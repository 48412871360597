import gammaStore from "@/store/gammaStore";

function SmartSuiteGPS() {
    this.gamma = null;
    this.tenant = null;
}

SmartSuiteGPS.prototype.setTenant = function (tenant) {
    this.tenant = tenant;
}

SmartSuiteGPS.prototype.connect = async function (tenant) {
    if (tenant !== undefined) {
        await this.setTenant(tenant);
    }
    this.gamma = await gammaStore.dispatch('getGPShub', this.tenant._id);
}

SmartSuiteGPS.prototype.close = async function () {
    await gammaStore.dispatch('closeGPShub', this.tenant);
};

SmartSuiteGPS.prototype.addSourceListener = async function (eventName) {
    this.gamma.sendMessage('AddSourceListener', eventName);
}

SmartSuiteGPS.prototype.removeSourceListener = async function (eventName) {
    this.gamma.sendMessage('RemoveSourceListener', eventName);
}

SmartSuiteGPS.prototype.getGPSTrackingFeed = async function (callback) {
    await this.checkConnection();
    await gammaStore.dispatch('setGPShubListener', {
        _id: this.tenant._id, event: 'gpsupdates', callback: (event) => {
            callback(event);
        }
    });
}

SmartSuiteGPS.prototype.getGpsDeviceIds = async function () {
    await this.checkConnection();
    return await this.gamma.sendMessage('GetGpsDeviceIdsAsync');
}

SmartSuiteGPS.prototype.getLastKnownPosition = async function (device) {
    await this.checkConnection();
    return await this.gamma.sendMessage('GetLastKnownPositionAsync', device);
}

SmartSuiteGPS.prototype.getDeviceHistory = async function (device, callback) {
    await this.checkConnection();
    this.gamma.streamMessage('GetDeviceHistoryAsync', device).subscribe({
        next: (item) => {
            callback(item);
        },
        complete: () => {
            callback("done");
        },
        error: (err) => {
            console.error(err);
            callback("done");
        }
    });
};

SmartSuiteGPS.prototype.getDeviceHistoryByTimeSpan = async function (device, from, to, callback) {
    await this.checkConnection();
    return this.gamma.streamMessage('GetDeviceHistoryByTimeSpanAsync', device, from, to).subscribe({
        next: (item) => {
            callback(item);
        },
        complete: () => {
            callback("done");
        },
        error: (err) => {
            console.error(err);
            callback("done");
        }
    });
};

SmartSuiteGPS.prototype.checkConnection = async function () {
    if (!this.gamma.isConnected() && !this.gamma.isConnecting()) {
        await this.connect();
    }
}

export default SmartSuiteGPS;