<template>

    <vue-draggable-resizable :id="'const'+ popUpId" :resizable="false" :parent="true" :z="zIndex"
                             :grid="[16, 9]" :w="400" :h="297" :x="xCoord" :y="yCoord"
                             @dragstop="adjustVideoPosition" @activated="zIndex = 1001" @deactivated="zIndex = 1000"
                             style="background-color: #2f353a; border: 1px solid #000000; height: 295px;">

        <b-card :id="'const-card' + popUpId" no-body header-tag="header" bg-variant="dark" style="margin: 0; max-height: 295px !important">

            <!--Header-->
            <div slot="header">
                <div class="d-flex w-100 justify-content-between">
                    <h3 style="margin: 0 2px; color: white;">{{ name }}</h3>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 6px">
                        <b-input-group prepend="History" size="sm">
                            <b-select :options="historyOptions" v-model="historyOption"
                                      @change="$emit('gpsHistoryToggle', {name: name, value: historyOption})"/>
                        </b-input-group>
                        <div>
                            <fa-icon :icon="['fas', 'close']" @click="closePopUp" class="d-flex justify-content-end"
                                 style="margin: 2px 2px 0 0; color: white; font-size: 20px; cursor: pointer"/>
                        </div>
                    </div>
                </div>
                <div v-if="historyOption === 2" style="margin-top: 10px">
                    <MDateTimePickerRange v-model="historyRange" :includeFuture="false" size="sm" :dateFlex="5" :timeFlex="3"/>
                </div>

            </div>

            <!--Cameras-->
            <div style="overflow-y: hidden; margin-top: 5px" :style="historyOption === 2 ? 'max-height: 98px' : 'max-height: 217px'">
                <div style="overflow-y: auto" :style="historyOption === 2 ? 'height: 98px' : 'height: 217px'">
                    <div v-for="(camera, index) in unmarkedCameras" :key="index" style="color: black; height: 30px;">
                        <!--Active Camera-->
                        <span v-if="camera.device.status === 'active'" class="d-flex w-100 justify-content-between"
                              style="margin: 0">
                            <h5 style="margin: 0; color: white; padding-left: 10px;">
                                {{ camera.getDeviceName() !== undefined ? camera.getDeviceName() : 'Unnamed Device' }}
                            </h5>
                            <fa-icon v-if="camera.device.openBool === true" :icon="['fas', 'eye-slash']" class="d-flex justify-content-end"
                               style="margin: 0; padding: 3px 10px 0 0; color: white; cursor: pointer" @click="closeCamera(camera)"/>
                            <fa-icon v-else :icon="['fas', 'eye']" class="d-flex justify-content-end"
                               style="margin: 0; padding: 3px 10px 0 0; color: white; cursor: pointer" @click="openVideoPopUp(camera)"/>
                        </span>

                        <!--Inactive Camera-->
                        <span v-else class="d-flex w-100" style="margin: 0">
                            <h5 style="margin: 0; color: red; padding-left: 10px;">{{ camera.getDeviceName() !== undefined ? camera.getDeviceName() : 'Unnamed Device' }}</h5>
                        </span>

                    </div>

                    <div v-show="unmarkedCameras && unmarkedCameras.length < 1" style="margin: auto;">
                        <i style="text-align: center; width: 100%; color: #FFFFFF;">no cameras</i>
                    </div>
                </div>
            </div>

        </b-card>

    </vue-draggable-resizable>

</template>

<script>
    import Vue from 'vue';
    import mapViewStore from '../../store/mapViewStore';
    import VueDraggableResizable from 'vue-draggable-resizable';
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
    import MDateTimePickerRange from "@/components/MDateTimePickerRange.vue";
    import PubSub from "pubsub-js";

    Vue.component('vue-draggable-resizable', VueDraggableResizable);

    export default {
        name: "GPSTrackingPopUp",
        components: {MDateTimePickerRange},

        props: {
            popUpId: {
                type: Number
            },
            name: {
                type: String
            },
            gpsTrackingPopUps: {
                type: Array
            },
            unmarkedCameras: {
                type: Array
            },
            historyType: {
                type: Number,
                required: false
            },
            range: {type: Object}
        },

        created() {
            // first, make sure the matrix is populated before determining where to place the pop-up
            // add the piece then repopulate the matrix
            mapViewStore.dispatch('setMatrix').then(()=> {
                this.findPlacement();
            }).then(() => {
                mapViewStore.dispatch('addPopUpPosition', {
                    type: 'gpsTracking',
                    id: this.popUpId,
                    coordinates: {
                        leftMost: this.xCoord,
                        topMost: this.yCoord
                    },
                    size: {
                        width: 400,
                        height: 297
                    }
                });
            }).then(() => {
                mapViewStore.dispatch('setMatrix');
            });
        },

        mounted() {
            let camerasLength = this.unmarkedCameras.length * 30 + 33;
            let calculatedLength = this.unmarkedCameras.length * 30 + 33 >= 295 ? camerasLength : 295;
            document.getElementById('const-card' + this.popUpId).style.height = JSON.stringify(calculatedLength) + 'px';
        },

        data() {
            return {
                windowHeight: mapViewStore.state.windowHeight,
                xCoord: 0,
                yCoord: 0,
                zIndex: 1000,
                historyOption: this.historyType,
                historyOptions: [
                    {text: 'Last Location', value: 1},
                    {text: '1 Hour', value: 3600},
                    {text: '8 Hour', value: 3600 * 8},
                    {text: '24 Hours', value: 86400},
                    {text: 'Custom Range', value: 2},
                    /*{text: 'Day', value: 3},*/
                    /*{text: 'Single Point', value: 4}*/
                ],
                historyRange: {
                    start: this.range.start,
                    end: this.range.end
                }
            }
        },

        methods: {
            closeCamera(camera) {
                PubSub.publish('closeStream' + camera.getDeviceId(), '0')
            },
            adjustVideoPosition(x, y) {
                // repopulate matrix with adjusted pop up position
                mapViewStore.dispatch('resizeMovePopUp', {
                    type: 'gpsTracking',
                    id: this.popUpId,
                    coordinates: {
                        leftMost: x,
                        topMost: y
                    },
                    size: {
                        width: 400,
                        height: 297
                    }
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            closePopUp() {
                // replaces GPS tracking array with new one that has GPS tracking pop up sliced out
                let newPopUps = [...this.gpsTrackingPopUps];
                const targetIndex = this.gpsTrackingPopUps.findIndex(gpsTrackingPopUp => {
                    return gpsTrackingPopUp.id === this.popUpId;
                });
                newPopUps.splice(targetIndex, 1);
                // let the MapView parent component know that the pop-up was removed
                this.$emit('closeGPSTrackingPopUp', newPopUps);
                // then repopulate the matrix with accounting for the pop-up missing
                mapViewStore.dispatch('removePopUpPosition', {
                    type: 'gpsTracking',
                    id: this.popUpId
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            findPlacement() {
                // starting at the top left, then traversing width then length, find the first placement
                // where all four corners of the placement in the matrix are populated by a false boolean
                // which represents there is no pop up populating the space
                for (let j = 0; j < mapViewStore.state.windowHeight - 297; j += 9) {
                    for (let i = 0; i < mapViewStore.state.windowWidth - 400; i += 16) {
                        const isGoodPlacement = mapViewStore.state.matrix[i][j] === false && mapViewStore.state.matrix[i + 399][j] === false
                            && mapViewStore.state.matrix[i][j + 296] === false && mapViewStore.state.matrix[i + 399][j + 296] === false;
                        if (isGoodPlacement === true) {
                            this.xCoord = i;
                            this.yCoord = j;
                            return;
                        }
                    }
                }
                // if there are no open placements on the screen, place the pop-up at the top left
                this.xCoord = 0;
                this.yCoord = 0;
            },
            openVideoPopUp(camera) {
                this.$set(camera.device, 'openBool', true);
                this.$emit('openUnmarkedVideoPopUp', camera);
            }
        },

        watch: {
            historyRange() {
                this.$emit('gpsHistoryToggle', {name: this.name, value: 2, range: this.historyRange});
            },
            range() {
                if (this.range.start === null) {
                    this.historyRange.start = null;
                    this.historyRange.end = null;
                }
            }
        },

        computed: {
            minHeight() {
                let camerasLength = this.unmarkedCameras.length * 30 + 33;
                let calculatedLength = this.unmarkedCameras.length * 30 + 33 >= 295 ? camerasLength : 295;
                document.getElementById('main-card').style.height = JSON.stringify(calculatedLength) + 'px';
                return calculatedLength;
            },
            minWidth() {
                let calculatedWidth = this.name.length * 15 + 25;
                if (calculatedWidth < 120) {
                    return 120;
                }
                return calculatedWidth;
            }
        }

    }
</script>

<style scoped>
header {
    padding: 10px;
}
</style>