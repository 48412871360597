<template>
    <div>

        <!-- Start Date/Time -->
        <div>

            <!-- Label -->
            <div>Start Date/Time:</div>

            <!-- Date/Time Picker -->
            <MDateTimePicker v-model="start" :stack="stack" :red-border="isError(start)" :size="size"
                             :dateFlex="dateFlex" :timeFlex="timeFlex"/>

            <!-- Error Text -->
            <div class="errorText">
                <div v-if="!includeFuture && start > now">
                    Date/Time can't be set in the future
                </div>
                <div v-else-if="!includePast && start < now">
                    Date/Time can't be set in the past
                </div>
                <div v-else-if="!max && start > max">
                    Date/Time can't be set after {{max.toLocaleString()}}
                </div>
                <div v-else-if="!min && start < min">
                    Date/Time can't be set before {{min.toLocaleString()}}
                </div>
                <div v-else-if="!start || !end"/>
                <div v-else-if="start >= end">
                    Can't set date/time after end date/time
                </div>
            </div>

        </div>

        <!-- End Date/Time -->
        <div>

            <!-- Label -->
            <div>End Date/Time:</div>

            <!-- Date/Time Picker -->
            <MDateTimePicker v-model="end" :stack="stack" :red-border="isError(end)" :size="size"
                             :dateFlex="dateFlex" :timeFlex="timeFlex"/>

            <!-- Error Text -->
            <div class="errorText">
                <div v-if="!includeFuture && end > now">
                    Date/Time can't be set in the future
                </div>
                <div v-else-if="!includePast && end < now">
                    Date/Time can't be set in the past
                </div>
                <div v-else-if="!max && end > max">
                    Date/Time can't be set after {{max.toLocaleString()}}
                </div>
                <div v-else-if="!min && end < min">
                    Date/Time can't be set before {{min.toLocaleString()}}
                </div>
                <div v-else-if="!start || !end"/>
                <div v-else-if="start >= end">
                    Cant set date/time before start date/time
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import MDateTimePicker from "@/components/MDateTimePicker.vue";

export default {
    components: {MDateTimePicker},
    props: {
        value: {
            type: Object
        },
        includeFuture: {
            type: Boolean,
            default: true
        },
        includePast: {
            type: Boolean,
            default: true
        },
        min: {
            type: Date
        },
        max: {
            type: Date
        },
        stack: {
            type: Boolean,
            default: false
        },
        size: {
            type: String
        },
        dateFlex: {
            type: Number,
            default: 1
        },
        timeFlex: {
            type: Number,
            default: 1
        }
    },
    created() {
        this.updateValue();
    },
    data() {
        return {
            start: this.value.start,
            end: this.value.end
        };
    },
    methods: {
        updateValue() {
            if (!this.isError(this.start) && !this.isError(this.start)) {
                this.$emit('input', Object.assign({
                    start: this.start,
                    end: this.end
                }, {}));
            }
        },
        isError(date) {
            return (
                (!this.includeFuture && date > this.now)
                || (!this.includePast && date < this.now)
                || (!this.max && date > this.max)
                || (!this.min && date < this.min)
                || this.start >= this.end
            );
        }
    },
    watch: {
        end() {
            this.updateValue();
        },
        start() {
            this.updateValue();
        },
    },
    computed: {
        now() {
            return new Date();
        }
    }

}
</script>

<style scoped>
.errorText {
    font-size: 10px;
    color: #f86c6b;
    height: 15px
}
</style>