<template>
    <div style="margin: -24px -30px 0 -30px; position: relative">

        <!-- Video/Timeline -->
        <div v-show="videoAside.bool"
             :id="'stream_settings_container_' + (videoAside.device ? videoAside.device.getDeviceId() : null) + videoAside.sourceToken + videoAside.dataChannel"
             :class="!videoAside.bool ? '' : 'd-flex flex-column align-content-stretch'"
             style="margin-top: -46px; position: absolute; z-index: 1003"
             :style="!videoAside.bool ? 'display: none' : 'height: calc(100vh - 106px); width: 100%'">
            <div id="streamSettings" class="flex-grow-1 flex-shrink-1 d-flex" style="min-height: 0"/>
            <SmartSuiteTimeline v-if="videoAside.device !== null && videoAside.bool" :sourceToken="videoAside.sourceToken"
                                :device="videoAside.device" :dataChannel="videoAside.dataChannel"/>
        </div>

        <!-- Video Aside -->
        <AppAside fixed style="margin-top: -45px">
            <VideoAside :name="videoAside.name" :device="videoAside.device"
                        :sourceToken="videoAside.sourceToken" :dataChannel="videoAside.dataChannel"
                        :mobile="mobileBool" @close="closeAside()" :bool="videoAside.bool"/>
        </AppAside>

        <!-- Video Pop Ups -->
        <VideoPopUp v-for="videoPopUp in videoPopUps" :key="videoPopUp.id" :popUpId="videoPopUp.id" :sensor="videoPopUp.sensor"
                   :name="videoPopUp.name" :aside="videoAside" :device="videoPopUp.device" :sourceToken="videoPopUp.sourceToken"
                   :dataChannel="videoPopUp.dataChannel" :popUps="videoPopUps" @closeVideoPopUp="closeVideoPopUp($event)"
                   @toggleAside="toggleVideoAside($event)">
        </VideoPopUp>

        <!-- GPS Tracking Pop Ups-->
        <GPSTrackingPopUp v-for="gpsTrackingPopUp in gpsTrackingPopUps" :key="gpsTrackingPopUp.id + 'uncam'"
                   :popUpId="gpsTrackingPopUp.id" :name="gpsTrackingPopUp.name" :gpsTrackingPopUps="gpsTrackingPopUps"
                   :unmarkedCameras="gpsTrackingPopUp.unmarkedCameras" @openUnmarkedVideoPopUp="toggleVideoPopUp($event)"
                   @closeGPSTrackingPopUp="gpsTrackingPopUps = $event" @gpsHistoryToggle="gpsHistoryToggle"
                   :historyType="gpsTrackingPopUp.historyType" :range="gpsTrackingPopUp.range">
        </GPSTrackingPopUp>

        <!-- Camera Group Pop Ups -->
        <CameraGroupPopUp v-for="cameraGroup in cameraGroups" :key="cameraGroup.id + 'cam'"
                   :name="cameraGroup.name" :cameraGroupId="cameraGroup.id" :cameraGroups="cameraGroups"
                   :cameras="cameraGroup.cameras" :device="cameraGroup.device"
                   @openVideoPopUp="toggleVideoPopUp(cameraGroup.device, $event)" @closeCameraGroupPopUp="cameraGroups = $event">
        </CameraGroupPopUp>

        <!-- Map -->
        <gmap-map ref="myMap" :center="center" :options="mapStyle" :zoom="11"
                  style="height: calc(100vh - 152px); width: 100%">

            <!-- GPS Tracking Markers -->
            <!-- TODO get GPS functional again with events -->
<!--            <div v-for="edge in Object.values(edgeGPS)">
                <gmap-marker :position="{lat: edge.curCoords.lat, lng: edge.curCoords.lng}" @click="toggleGPSTrackingPopUp(edge)"
                             :title="edge.edge + ' ' + (new Date(edge.curCoords.timeStamp * 1000)).toLocaleString()"
                             :icon="gpsTrackingIcon()" :zIndex="501"/>
                <gmap-marker v-if="edge.historyType === 1" :position="{lat: edge.prevCoords.lat, lng: edge.prevCoords.lng}"
                             :title="edge.edge + ' ' + (new Date(edge.prevCoords.timeStamp * 1000)).toLocaleString()"
                             :icon="gpsTrackingIcon(1)" :zIndex="500" @click="toggleGPSTrackingPopUp(edge)"/>
                <div v-else>
                    <gmap-marker :position="{lat: coord.lat, lng: coord.lng}" @click="toggleGPSTrackingPopUp(edge)"
                                 v-for="coord in edge.history" :icon="gpsTrackingIcon(2)"
                                 :zIndex="500" :title="dateHistoryString(edge.edge, coord)"/>
                    <gmap-polyline :path="edge.history" :options="{ strokeColor: '#d85020', strokeWeight: 3 }"
                                 :zIndex="500"/>
                </div>
            </div>-->

            <!-- Camera Markers -->
            <gmap-marker v-for="(device, index) in devices" :key="index + device.getDeviceId()"
                         v-if="device.getLatitude() !== 0 && device.getLongitude() !== 0"
                         :icon="getIcon(device)"
                         :clickable="true"
                         @click="toggleVideoPopUp(device)"
                         :title="device.getDeviceName()"
                         :position="{lat: device.getLatitude(), lng: device.getLongitude()}">
            </gmap-marker>

            <!-- use to artificially populate map with several device -->
            <gmap-marker v-if="tempArrayBool === true" v-for="(device, index) in tempArray" :key="index + device.lat + device.lng"
                         :icon="gpsTrackingIcon()"
                         :clickable="true"
                         @click="toggleVideoPopUp(device)"
                         :position="{lat: device.lat, lng: device.lng}">
            </gmap-marker>

        </gmap-map>
        <!--<b-button style="position: absolute" variant="dark" @click="tempArrayBool = !tempArrayBool">Demo</b-button>-->

    </div>
</template>

<script>
    import {isMobile} from 'mobile-device-detect';
    import VideoPopUp from "./VideoPopUp";
    import CameraGroupPopUp from "./CameraGroupPopUp";
    import GPSTrackingPopUp from "./GPSTrackingPopUp.vue";
    import { Aside as AppAside } from '../template_files/navbarJazz';
    import AsideVideoToggler from "./AsideVideoToggler";
    import VideoAside from "@/views/video_wall/videoAside";
    import tenantStore from "@/store/tenantStore";
    import deviceStore from "@/store/deviceStore";
    import PubSub from "pubsub-js";
    import SmartSuiteTimeline from "@/views/video_wall/SmartSuiteTimeline.vue";
    import googleMapSetup from '@/shared/mixins/googleMapSetup';
    import SmartSuiteGPS from "@/shared/smartsuite_services/smartsuite_gps";
    import mapViewStore from "@/store/mapViewStore";

    export default {
        mixins: [googleMapSetup],
        components: {
            SmartSuiteTimeline,
            VideoAside,
            AppAside,
            AsideVideoToggler,
            VideoPopUp,
            CameraGroupPopUp,
            GPSTrackingPopUp
        },
        computed: {
            mapStyle() {
                return Object.assign(this.mapOptions, {fullscreenControl: false})
            }
        },
        data: () => {
            return {
                tempArrayBool: false,
                tempArray: [{lat: 32.67684, lng: -116.43548}, {lat: 32.60050, lng: -116.46253}, {lat: 32.59785, lng: -116.37116}, {lat: 32.60592, lng: -116.32872}, {lat: 32.6482, lng: -116.2764}, {lat: 32.7173, lng: -116.449}, {lat: 32.647674, lng: -116.318076}, {lat: 32.781998, lng: -116.424439}, {lat: 32.682151, lng: -116.291527}, {lat: 32.60661, lng: -116.47189}, {lat: 32.79678, lng: -116.49596}, {lat: 32.81235, lng: -116.50809}, {lat: 29.459700, lng: -101.032100}, {lat: 29.38151, lng: -101.01038}, {lat: 29.36897, lng: -100.98060}, {lat: 29.33542, lng: -100.92814}, {lat: 29.30745, lng: -100.88490}, {lat: 29.371698, lng: -100.870882}, {lat: 29.42519, lng: -101.04079}, {lat: 29.32899, lng: -100.93011}, {lat: 29.32991, lng: -100.92446}, {lat: 29.32952, lng: -100.92390}, {lat: 29.26489, lng: -100.81387}, {lat: 29.25002, lng: -100.79543}, {lat: 29.72323, lng: -100.81422}, {lat: 31.78940, lng: -106.58300}, {lat: 31.78420, lng: -106.75800}, {lat: 31.91480, lng: -106.69100}, {lat: 31.793147, lng: -106.53854}, {lat: 31.783979, lng: -106.90714}, {lat: 31.820650, lng: -106.69981}, {lat: 31.835237, lng: -106.570015}, {lat: 31.82065, lng: -106.69981}, {lat: 31.783871, lng: -107.37230}, {lat: 31.78381, lng: -107.28802}, {lat: 31.783858, lng: -107.16123}, {lat: 31.784056, lng: -106.98941},],
                edgeGPS: {},
                ssGPSs: {},
                gpsHistory: null,
                cameraGroupCount: 0,
                cameraGroups: [],
                center: {
                    lat: 42.2711,
                    lng: -89.0940
                },
                gpsTrackingPopUps: [],
                gpsTrackingPopUpCount: 0,
                mobile: false,
                mobileBool: isMobile,
                videoPopUps: [],
                videoAside: {
                    bool: false,
                    name: '',
                    device: null,
                    sourceToken: null,
                    dataChannel: null
                },
                videoPopUpCount: 0,
                devices: null,
                pubsubs: [],
            }
        },
        async created() {
            await this.getAllDevices();
        },
        async beforeDestroy() {
            await deviceStore.dispatch('clearSourceListeners');
            this.pubsubs.forEach(sub => {
                PubSub.unsubscribe(sub);
            })
        },
        methods: {
            async gpsHistoryToggle(data) {
                let {name, value, range} = data;
                let gpsObj = this.edgeGPS[name];
                if (gpsObj.dataStream && gpsObj.dataStream.subject) {
                    await gpsObj.dataStream.subject.cancelCallback();
                    gpsObj.dataStream = null;
                }
                this.$set(gpsObj, 'historyType', value)
                if (value === 1 || (value === 2 && (!range && !gpsObj.range.start))) {
                    this.$set(gpsObj, 'history', null);
                } else {
                    let ssGPS = this.ssGPSs[gpsObj.tenant];
                    let start, end;
                    if (range) {
                        end = range.end.getTime() / 1000;
                        start = range.start.getTime() / 1000;
                        this.$set(gpsObj.range, 'end', range.end);
                        this.$set(gpsObj.range, 'start', range.start);
                    } else {
                        end = Math.floor(Date.now() / 1000);
                        start = end - value;
                        this.$set(gpsObj.range, 'end', null);
                        this.$set(gpsObj.range, 'start', null);
                    }
                    this.$set(gpsObj, 'history', []);
                    gpsObj.dataStream = await ssGPS.getDeviceHistoryByTimeSpan(name, start, end, data => {
                        if (data === 'done') {
                            gpsObj.dataStream = null;
                        } else if (!gpsObj.history[data.latitude + data.longitude]) {
                            gpsObj.history.push({
                                lat: parseFloat(data.latitude),
                                lng: parseFloat(data.longitude),
                                timeStamp: new Date(data.timeStamp * 1000)
                            })
                        }
                    });
                    if (!gpsObj.updateHistoryIntervalId && value !== 2 && value !== 3 && value !== 4) {
                        gpsObj.updateHistoryIntervalId = setInterval(() => {
                            this.removeOldestLocation(gpsObj.edge, new Date(Date.now() - (value * 1000)));
                        }, 30000);
                    } else {
                        clearInterval(gpsObj.updateHistoryIntervalId);
                        this.$set(gpsObj, 'updateHistoryIntervalId', null);
                    }
                }
            },
            getTitle(device) {
                if (this.device.getMultiSensor() > 1) {
                    return device.getDeviceStreamName(this.dataChannel);
                } else {
                    return device.getDeviceName();
                }
            },
            gpsTrackingIcon(type) {
                if (!type) {
                    return {
                        url: require("../../../public/img/deviceIcons/Track_up.png"),
                        scaledSize: new google.maps.Size(28, 28),
                    };
                } if (type === 1) {
                    return {
                        url: require("../../../public/img/deviceIcons/Track_ghost.png"),
                        scaledSize: new google.maps.Size(28, 28)
                    };
                }
                return {
                    url: require("../../../public/img/deviceIcons/PathPoint.png"),
                    scaledSize: new google.maps.Size(13, 13)
                };
            },
            closeVideoPopUp(event) {
                this.$set(event.camera.device, 'openBool', false);
                this.videoPopUps = event.videoPopUps;
            },
            async fitMapBounds() {
                let bounds = new (await this.google).maps.LatLngBounds();
                let map = this.$refs.myMap.$mapObject;
                this.devices.forEach(device => {
                    let coords = {lat: device.getLatitude(), lng: device.getLongitude()};
                    if (coords.lat !== 0 && coords.lng !== 0) {
                        bounds.extend(coords);
                    }
                });
                Object.values(this.edgeGPS).forEach(gpsDevice => {
                    let coords = {lat: gpsDevice.curCoords.lat, lng: gpsDevice.curCoords.lng};
                    if (coords.lat !== 0 && coords.lng !== 0) {
                        bounds.extend(coords);
                    }
                });
                map.setCenter(bounds.getCenter());
                (await this.google).maps.event.addListenerOnce(map, 'bounds_changed', function () {
                    this.setZoom(map.getZoom());
                });
                map.fitBounds(bounds);
            },
            async getAllDevices() {
                this.devices = [];
                let tenants = [];

                // Get Tenants
                tenants = await tenantStore.dispatch('getTenants').catch(error => {
                    console.error(error);
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Tenant couldn't be retrieved: " + error.response.statusText,
                        style: 'error'
                    });
                });

                // Looping Through Each Tenant
                for (const tenant of tenants) {
                    this.pubsubs.push(PubSub.subscribe('edgeServicesUpdate', async (msg, data) => {
                        if (data.tenantId === tenant._id) {
                            if (data.edgeServices) {
                                for (const edgeService of data.edgeServices) {
                                    let devices = await deviceStore.dispatch('getSmartSuiteDevicesByEdgeService', {
                                        edgeService: edgeService,
                                        tenantId: tenant._id,
                                        isConnected: true
                                    });
                                    if (devices !== undefined) {
                                        this.updateDevices(devices);
                                    }
                                }
                            }
                        }
                    }));
                    await deviceStore.dispatch('setMediahubListener', tenant._id);

                    // Start GPS Tracking
                    let ssGPS = this.ssGPSs[tenant._id] = await new SmartSuiteGPS();
                    await ssGPS.connect(tenant);
                    await ssGPS.getGPSTrackingFeed(data => {
                        let gpsObj = this.edgeGPS[data.gpsDeviceId]
                        gpsObj.prevCoords = Object.assign({}, gpsObj.curCoords)
                        gpsObj.curCoords = Object.assign({}, {
                            lat: parseFloat(data.latitude),
                            lng: parseFloat(data.longitude),
                            timeStamp: data.timeStamp
                        })
                        if (gpsObj.historyType !== 2 && gpsObj.history) {
                            gpsObj.history.push({
                                lat: parseFloat(data.latitude),
                                lng: parseFloat(data.longitude),
                                timeStamp: new Date(data.timeStamp * 1000)
                            })
                        }
                    });
                    let edgeIds = await ssGPS.getGpsDeviceIds();
                    for (const edge of edgeIds) {
                        let lastKnownPos = await ssGPS.getLastKnownPosition(edge);
                        if (lastKnownPos[0] && lastKnownPos[1]) {
                            this.edgeGPS[edge] = Object.assign({
                                edge: edge,
                                curCoords: {
                                    lat: parseFloat(lastKnownPos[0].latitude),
                                    lng: parseFloat(lastKnownPos[0].longitude),
                                    timeStamp: lastKnownPos[0].timeStamp
                                },
                                prevCoords: {
                                    lat: parseFloat(lastKnownPos[1].latitude),
                                    lng: parseFloat(lastKnownPos[1].longitude),
                                    timeStamp: lastKnownPos[1].timeStamp
                                },
                                devices: [],
                                tenant: tenant._id,
                                history: null,
                                historyType: 1,
                                range: {
                                    start: null,
                                    end: null
                                },
                                dataStream: null,
                                updateHistoryIntervalId: null,
                            }, {});
                            await ssGPS.addSourceListener(edge);
                        }
                    }

                    // Get Device Updates For Tenant
                    this.pubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, (msg, data) => {
                        this.updateDevices(data);
                    }));
                }

            },
            removeOldestLocation(name, afterTime) {
                this.edgeGPS[name].history = this.edgeGPS[name].history.filter(_ => _.timeStamp > afterTime);
            },
            dateHistoryString(name, mainCoord) {
                return this.edgeGPS[name].history.reduce((acc, coord) => {
                    if (mainCoord.lat === coord.lat && mainCoord.lng === coord.lng) {
                        return acc + '\n' + coord.timeStamp.toLocaleString()
                    }
                    return acc
                }, name)
            },
            updateDevices(devices) {
                devices.map(device => {
                    let edge = Object.keys(this.edgeGPS).findIndex(_ => _ === device.getHubName());
                    if (edge !== -1) {
                        let update = Object.values(this.edgeGPS)[edge].devices.findIndex(_ => _.getDeviceId() === device.getDeviceId());
                        if (update !== -1) {
                            Object.values(this.edgeGPS)[edge].devices[update].updateDevice(device.device);
                        } else if (device.getDeviceType() === 'camera') {
                            Object.values(this.edgeGPS)[edge].devices.push(device);
                        }
                    } else {
                        let update = this.devices.findIndex(_ => _.getDeviceId() === device.getDeviceId());
                        if (update !== -1) {
                            this.devices[update].updateDevice(device.device);
                        } else if (device.getDeviceType() === 'camera') {
                            this.devices.push(device);
                        }
                    }
                });
            },
            getIcon(device) {
                switch (device.getDeviceType()) {
                    case "camera":
                        if (device.getMultiSensor() > 1 || device.getDeviceModel().includes('4308')) {
                            return {
                                url: device.getDetectionStatus() !== 2 ? require("../../../public/img/deviceIcons/MultiCamera_up.png")
                                    : require("../../../public/img/deviceIcons/MultiCamera_down.png"),
                                scaledSize: new google.maps.Size(37, 37)
                            };
                        }
                        return {
                            url: device.getDetectionStatus() !== 2 ? require("../../../public/img/deviceIcons/Camera_up.png")
                                : require("../../../public/img/deviceIcons/Camera_down.png"),
                            scaledSize: new google.maps.Size(37, 37)
                        };
                    default:
                        return {
                            url: device.getDetectionStatus() !== 2 ? require("../../../public/img/deviceIcons/Unknown_up.png")
                                : require("../../../public/img/deviceIcons/Unknown_down.png"),
                            scaledSize: new google.maps.Size(30, 30)
                        };
                }
            },
            toggleGPSTrackingPopUp(obj) {
                const targetIndex = this.gpsTrackingPopUps.findIndex(gpsTrackingPopUp => {
                    return gpsTrackingPopUp.deviceId === obj.edge;
                });
                if (~targetIndex) {
                    mapViewStore.dispatch('removePopUpPosition', {
                        type: 'gpsTracking',
                        id: this.gpsTrackingPopUps[targetIndex].id
                    }).then(() => {
                        mapViewStore.dispatch('setMatrix');
                    });
                    this.gpsTrackingPopUps.splice(targetIndex, 1);
                } else {
                    this.gpsTrackingPopUps.push({
                        id: this.gpsTrackingPopUpCount,
                        name: obj.edge,
                        unmarkedCameras: obj.devices,
                        address: obj.curCoords,
                        deviceId: obj.edge,
                        historyType: obj.historyType,
                        range: obj.range
                    });
                    this.gpsTrackingPopUpCount++;
                }
            },
            toggleVideoPopUp(device, sensor) {
                //if it is a quad or panoramic device
                if ((device.getMultiSensor() > 1 || device.getDeviceModel().includes('4308')) && !sensor) {
                    const targetIndex = this.cameraGroups.findIndex(group => {
                        return group.deviceId === device.getDeviceId();
                    });
                    if (~targetIndex) {
                        mapViewStore.dispatch('removePopUpPosition', {
                            type: 'cameraGroup',
                            id: this.cameraGroups[targetIndex].id
                        }).then(() => {
                            mapViewStore.dispatch('setMatrix');
                        });
                        this.cameraGroups.splice(targetIndex, 1);
                    } else {
                        this.cameraGroups.push({
                            id: this.cameraGroupCount,
                            device: device,
                            name: device.getDeviceName(),
                            cameras: device.getMultiSensorStreams(),
                            deviceId: device.getDeviceId()
                        });
                        this.cameraGroupCount++;
                    }
                } else if (device.getDeviceType() === 'camera') {
                    const targetIndex = this.videoPopUps.findIndex(video => {
                        return video.deviceId === device.getDeviceId() && video.sourceToken === (sensor ? sensor.sourceToken : '0');
                    });
                    if (~targetIndex) {
                        mapViewStore.dispatch('removePopUpPosition', {
                            type: 'video',
                            id: this.videoPopUps[targetIndex].id
                        }).then(() => {
                            mapViewStore.dispatch('setMatrix');
                        });
                        this.videoPopUps.splice(targetIndex, 1)
                    } else {
                        this.videoPopUps.push({
                            id: this.videoPopUpCount,
                            name: sensor ? sensor.friendlyName : device.getDeviceName(),
                            device: device,
                            sensor: sensor,
                            sourceToken: sensor ? sensor.sourceToken : '0',
                            dataChannel: undefined,
                            deviceId: device.getDeviceId()
                        });
                        this.videoPopUpCount++;
                    }
                }
            },
            toggleVideoAside(event) {
                this.videoAside = event;
            }
        },

        watch: {
            async devices() {
                await this.fitMapBounds();
            }
        },
    }

</script>

<style>
    .span.navbar-toggler-icon {
        color: white;
    }
</style>
