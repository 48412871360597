<template>

    <!--Button that triggers aside toggle-->
    <button :display="display"
            :mobile="mobile"
            :class="classList"
            style="padding: 0;"
            type="button"
            @click="asideToggle">

        <fa-icon :icon="['fas', 'film']" class="d-flex" style="color: white;"/>

    </button>

</template>

<script>

import {asideMenuCssClasses, checkBreakpoint, validBreakpoints} from "@/shared/classes";
import toggleClasses from "@/shared/toggle-classes";

    export default {
        name: 'AsideVideoToggler',
        props: {
            asideOpen: {
                type: Boolean
            },
            defaultOpen: {
                type: Boolean,
                default: false
            },
            display: {
                type: String,
                default: 'lg'
            },
            mobile: {
                type: Boolean,
                default: false
            },
            name: {
                type: String
            },
            nameAside: {
                type: String
            },
            popUpsLength: {
                type: Number,
                default: 0
            }
        },
        computed: {
            classList () {
                return [
                    'navbar-toggler'
                ];
            }
        },
        mounted: function() {
            if (this.popUpsLength < 1) {
                this.toggle(this.defaultOpen);
            }
        },
        methods: {
            toggle (force) {
                const [display, mobile] = [this.display, this.mobile];
                let cssClass = asideMenuCssClasses[0];
                if (!mobile && display && checkBreakpoint(display, validBreakpoints)) {
                    cssClass = `aside-menu-${display}-show`;
                }
                toggleClasses(cssClass, asideMenuCssClasses, force);
            },
            asideToggle (e) {
                e.preventDefault();
                let newAsideBool = this.name !== this.nameAside;
                // only toggle if either the video being expanded is already in the aside or the aside is closed
                if (!newAsideBool || !this.asideOpen) {
                    this.toggle();
                }
                this.$emit('toggleVideoAside');
            }
        }
    }
</script>

<style>
button:focus {
    border: none;
    outline: none;
}
</style>