import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        matrix: [],
        popUpPositions: [],
        windowHeight: window.innerHeight - 152,
        windowWidth: window.innerWidth - 50
    },

    mutations: {
        PUSH_POSITION(state, position) {
            state.popUpPositions.push(position);
        },
        REMOVE_POSITION(state, position) {
            // filters out a pop-up from the popUpPositions array after it is deleted
            let newPopUps = [];
            state.popUpPositions.forEach(popUp => {
                if (popUp.id !== position.id || popUp.type !== position.type) {
                    newPopUps.push(popUp);
                }
            });
            state.popUpPositions = newPopUps;
        },
        RESIZE_MOVE_POSITION(state, position) {
            // instantiates the coordinates and size of a pop-up after it is moved
            for (let i = 0; i < state.popUpPositions.length; i++) {
                if (state.popUpPositions[i].id === position.id && state.popUpPositions[i].type === position.type) {
                    state.popUpPositions[i] = position;
                }
            }
        },
        SET_LAYOUT_MATRIX(state) {
            // populates a 2-dimensional array with width x height of screen,
            // populated with false booleans representing the pixel isn't populated by a pop-up
            for (let i = 0; i < state.windowWidth; i++) {
                state.matrix[i] = new Array(state.windowHeight).fill(false);
            }
            // populates the matrix with true booleans where pixels are populated by a pop-up
            for (let i = 0; i < state.popUpPositions.length; i++) {
                for (let k = Math.floor(state.popUpPositions[i].coordinates.leftMost); k < Math.floor(state.popUpPositions[i].coordinates.leftMost) + state.popUpPositions[i].size.width; k++) {
                    for (let z = Math.floor(state.popUpPositions[i].coordinates.topMost); z < Math.floor(state.popUpPositions[i].coordinates.topMost) + state.popUpPositions[i].size.height; z++) {
                        state.matrix[k][z] = true;
                    }
                }
            }
        }
    },

    actions: {
        addPopUpPosition({ commit }, position) {
            commit('PUSH_POSITION', position);
        },
        removePopUpPosition({ commit }, position) {
            commit('REMOVE_POSITION', position);
        },
        resizeMovePopUp({ commit }, position) {
            commit('RESIZE_MOVE_POSITION', position);
        },
        setMatrix({ commit }) {
            commit('SET_LAYOUT_MATRIX');
        }
    },

    getters: {
        getPopUp: (state) => (type, id) => {
            // retrieves and returns the popUp with the corresponding type and id
            let popUp = {};
            for (let i = 0; i < state.popUpPositions.length; i++) {
                if (type === state.popUpPositions[i].type && id === state.popUpPositions[i].id) {
                    popUp = state.popUpPositions[i];
                }
            }
            return popUp;
        }
    }
});
